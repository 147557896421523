@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: white;
  color: black;
}

@font-face {
  font-family: inter;
  src: url("./assets/fonts/Inter-VariableFont_opsz\,wght.ttf");
}
@font-face {
  font-family: poppinsMedium;
  src: url("./assets/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: poppinsLight;
  src: url("./assets/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: poppinsExtraLight;
  src: url("./assets/fonts/Poppins-ExtraLight.ttf");
}
@font-face {
  font-family: poppinsSemiBold;
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

.input-container {
  @apply w-[100%] flex items-stretch rounded-md;
}
.input-text {
  @apply w-full h-full focus:outline-none flex-1 text-black/[0.5]   rounded-md border-l-0 rounded-tl-none rounded-bl-none;
}

.in-shop {
  @apply bg-white border border-[#0000001F] rounded-md;
}

.in-onboard {
  @apply bg-[#B7BBC11F];
}

.continue {
  box-shadow: 6px 6px 0px 0px #11281c;
  @apply bg-custom-green-primary w-full mt-5 rounded-md text-white font-inter font-medium p-3;
}
.diminishing-line {
  width: 100%; /* Adjust the width to fit your container */
  height: 1px; /* Adjust the height for the thickness of the line */
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0.3) 50.27%,
    rgba(0, 0, 0, 0.05) 100%
  );
}
::-webkit-scrollbar {
  display: none;
}

.cutoff-text {
  --max-lines: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}
.cutoff-text-one {
  --max-lines: 1;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}
.confirm-order-detail-type {
  @apply flex justify-between items-center p-2;
}
.confirm-order-detail-value {
  @apply text-end font-inter text-black text-[0.85rem] font-[400] max-w-[50%] overflow-hidden overflow-ellipsis;
}
.customer-info-cont {
  @apply flex justify-between items-center p-2;
}
.customer-info-detail-type {
  @apply font-inter text-[#606F77] text-[0.825rem] font-[400];
}
.customer-info-detail-value {
  @apply text-end font-inter text-black text-[0.85rem] font-[400] max-w-[50%] overflow-hidden overflow-ellipsis;
}
.disabled {
  @apply opacity-50;
}

.slide {
  animation: slide 800ms ease-in-out;
}
/* .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 50;
} */

@keyframes slide {
  0% {
    top: -50%;
    scale: 0;
  }

  100% {
    /* right: 2%; */
    scale: 1;
  }
}
